import React, { useContext } from 'react'
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from '../global/boton/ButtonContent';
import { BsArrowReturnRight } from 'react-icons/bs';

const SlideLogoForm = ({ image, text, sloganPrincipal, title, listsServices }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bgCountent relative w-full h-[1200px] md:h-[800px]' style={{ backgroundImage: `url("${image ? image : rpdata?.stock?.[7]}")` }}>
            <div className='absolute w-full h-auto'>
                <div className='w-4/5 mx-auto flex flex-col justify-center text-center z-50 space-y-4'>
                    <div className="w-[80%] md:w-[30%] flex justify-center mx-auto  rounded-xl">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt="CompanyLogo"
                            className="w-full flex"
                            data-aos="zoom-in"
                            data-aos-duration="1000"
                        />
                    </div>
                    <div className='text-white text-center'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed.length > 1 ?
                                    <h5 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h5>
                                    : <h2 className='pb-3 capitalize'>we have {rpdata?.dbPrincipal?.exprYears} years of experience </h2>
                                :
                                <h5 className='pb-3 capitalize font-extrabold text-[50px]'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h5>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                        {
                            listsServices ?
                                <ul className="grid grid-cols-1 md:grid-cols-2 pb-5">
                                    {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                        return (
                                            <li key={index} className="py-1 flex items-center lg:ml-[210px]">
                                                <BsArrowReturnRight />
                                                <span>{item.name}</span>
                                            </li>
                                        )
                                    })}
                                </ul>
                                : null
                        }
                    </div>

                    <div >
                        <ButtonContent />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SlideLogoForm;
